import React, { useEffect } from "react"
import { navigate } from "gatsby"
import NewsletterForm from "../../components/NewsletterForm"
import CareersHelmet from "../../sections/OldDesign/careers/CareersHelmet"
import CareersHero from "../../sections/OldDesign/careers/CareersHero"
import JobDescription from "../../sections/OldDesign/careers/job/JobDescription"
import Layout from "../../templates/Layout"

const redirectIfNoJobId = () => {
    if (!window) return
    const params = new URLSearchParams(window.location.search)
    const ghJobId = params.get("gh_jid")
    if (!ghJobId) navigate("/careers")
}

const Careers = () => {
    useEffect(() => {
        redirectIfNoJobId()
    }, [])
    return (
        <Layout>
            <div className="bg-horizen-dark-blue">
                <CareersHelmet />
                <CareersHero />
                <JobDescription />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Careers
