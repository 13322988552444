import React, { useEffect, useState } from "react"
import Loading from "../../../../components/Loading"
import ContentContainer from "../../../../templates/ContentContainer"

const JobDescription = () => {
    const [isLoading, setIsloading] = useState(true)

    useEffect(() => {
        const script = document.createElement("script")
        script.src =
            "https://boards.greenhouse.io/embed/job_board/js?for=horizen"
        script.async = true
        script.onload = () => {
            setIsloading(false)
        }
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <section className="bg-white py-16" id="section-open-positions">
            <ContentContainer>
                {isLoading ? <Loading /> : <></>}
                <div id="grnhse_app" className="-mx-[20px]" />
            </ContentContainer>
        </section>
    )
}

export default JobDescription
